import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';

import { AuthService } from '../auth.service';

import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  email:string;
  password: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', { 
        validators: [Validators.required, Validators.email]
      }),

      password: new FormControl('', { 
        validators: [Validators.required]})
    });
  }

  onSubmit() {
    this.authService.login({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    });
    // Close when submit
    this.dialog.closeAll();
  }

}

