// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyBUVpqPMghAWXTggP21MkLnUdq9viaX9oQ",
    // authDomain: "new-eskino.firebaseapp.com",
    // databaseURL: "https://new-eskino.firebaseio.com",
    // projectId: "new-eskino",
    // storageBucket: "new-eskino.appspot.com",
    // messagingSenderId: "267899287336",
    // appId: "1:267899287336:web:680b595b28f33a6e"


    apiKey: "AIzaSyCafUNdPluc-E-LbDuMRHRizkYgRLIxwnQ",
    authDomain: "eskino-dev.firebaseapp.com",
    databaseURL: "https://eskino-dev.firebaseio.com",
    projectId: "eskino-dev",
    storageBucket: "eskino-dev.appspot.com",
    messagingSenderId: "682919476600",
    appId: "1:682919476600:web:8ab64c369e41e41f"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
