import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Registration } from '../models/Registration';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private afs: AngularFirestore) {
    this.registrationsCollection = this.afs.collection('registrations', (ref) => ref.orderBy('courseDate', 'asc'));
  }
  registrationsCollection: AngularFirestoreCollection<Registration>;
  registrationDoc: AngularFirestoreDocument<Registration>;
  registrations: Observable<Registration[]>;
  registration: Observable<Registration>;

  getRegistrations(): Observable<Registration[]> {
    // Get the Courses with the id
    this.registrations = this.registrationsCollection.snapshotChanges().map((changes) => {
      return changes.map((action) => {
        const data = action.payload.doc.data() as Registration;
        data.id = action.payload.doc.id;
        return data;
      });
    });

    return this.registrations;
  }

  newRegister(registration: Registration) {
    this.registrationsCollection.add(registration);
  }

  getRegistrationDetails(id: string): Observable<Registration> {
    this.registrationDoc = this.afs.doc<Registration>(`registrations/${id}`);

    this.registration = this.registrationDoc.snapshotChanges().map((action) => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as Registration;
        data.id = action.payload.id;
        return data;
      }
    });

    return this.registration;
  }

  updateRegistration(registration: Registration) {
    this.registrationDoc = this.afs.doc(`registrations/${registration.id}`);
    this.registrationDoc.update(registration);
  }

  deleteRegistration(id) {
    this.registrationDoc = this.afs.doc(`registrations/${id}`);
    this.registrationDoc.delete();
  }
}
