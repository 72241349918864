// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthService {

//   constructor() { }
// }


import { Subject } from 'rxjs/Subject';
import { User } from './user.model';
import { AuthData } from './auth-data.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs/Observable';
import { NotificationService } from 'src/app/service/notification.service';
// import { resolve } from 'path';


@Injectable()
export class AuthService {
    authChange = new Subject<boolean>();
    private isAuthenticated= false;

    constructor(
        private router: Router,
        private afAuth: AngularFireAuth,
        private notificationService: NotificationService) { }

    addUser( authData: AuthData ) {
        this.afAuth.auth
            .createUserWithEmailAndPassword(
                authData.email, 
                authData.password
                ).then(result => {
                    this.notificationService.success(':: Der neue Benutzer wurde erfolgreich angelegt');
            })
            .catch(error => {
                this.notificationService.warn(':: Ungültiger Nutzer: bitte versuchen Sie es erneut.')
            });
    }

    login(authData: AuthData) {
        this.afAuth.auth.signInWithEmailAndPassword(
            authData.email,
            authData.password
        ).then(result => {
            this.authSuccessfully();
        })
            .catch(error => {
                // Notification when sucess
                this.notificationService.warn(':: Ungültige Eingabe. Bitte versuchen Sie es erneut.');
            });
    }

    isAuth() {
        return this.isAuthenticated;
        
        }

    private authSuccessfully() {
        this.isAuthenticated = true;
        this.authChange.next(true);
        this.router.navigate(['/dashboard']);
        this.notificationService.success(':: Willkommen im Verwaltungs-System von Eskino');
    }

    logout() {
        this.authChange.next(false);
        this.router.navigate(['/']);
        this.isAuthenticated = false;
        this.notificationService.warn(':: Erfolgreich abgemeldet.');
    }
}

