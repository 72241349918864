import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { Contact } from "../models/Contact";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  contactsCollection: AngularFirestoreCollection<Contact>;
  contactDoc: AngularFirestoreDocument<Contact>;
  contacts: Observable<Contact[]>;
  contact: Observable<Contact>;

  constructor(private afs: AngularFirestore) {
      this.contactsCollection = this.afs.collection('contacts',
          ref => ref.orderBy('lastName', 'asc'))
  }

  getAvailableContacts(): Observable<Contact[]> {
      
      //Get the Contacts with the id
      this.contacts = this.contactsCollection.snapshotChanges().map(changes => {
          return changes.map(action => {
              const data = action.payload.doc.data() as Contact;
              data.id = action.payload.doc.id;
              return data;
          });

      });

      return this.contacts;

  }
  newContact(contact: Contact) {
      this.contactsCollection.add(contact);

  }

  getContactDetails(id: string): Observable<Contact> {
      this.contactDoc = this.afs.doc<Contact>(`contacts/${id}`);

      this.contact = this.contactDoc.snapshotChanges().map(action => {
          if(action.payload.exists === false) {
              return null;
          } else {
              const data = action.payload.data() as Contact;
              data.id = action.payload.id;
              return data;
          }
      });

      return this.contact;
  }


  updateContact(contact: Contact) {
      this.contactDoc = this.afs.doc(`contacts/${contact.id}`);
      this.contactDoc.update(contact);
  }

  deleteContact(id) {
      this.contactDoc = this.afs.doc(`contacts/${id}`);
      this.contactDoc.delete();
  }
}
