import { Component, OnInit, ViewChild } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';


import { Contact } from 'src/app//models/Contact';
import { ContactService } from 'src/app/service/contact.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { timeout } from 'rxjs/operators';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  
  

  constructor(
    // private flashMessage: FlashMessagesService,
    private contactService: ContactService,
    private router: Router,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private flashMessage: FlashMessagesService
    ) { }

    contact: Contact = { 
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
    };

    @ViewChild('cf', {static:true}) form: any;

  ngOnInit() {
  }

  onSubmit({value, valid}: {value: Contact, valid: boolean}) {
      // Add new Contact
      this.contactService.newContact(value);

      // Close when submit
      this.dialog.closeAll();
      // Show Success MSG
      this.notificationService.success(':: Danke für Ihre Nachricht. Wir werden uns so schnell wie möglich zurückmelden.');
      // Count Submition
      // Open a dialog box (POP UP)
      // Redirect
      this.router.navigate(['']);
    }
  }
