
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RegistrationService } from 'src/app//service/registration.service';
import { Registration } from 'src/app//models/Registration';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { CourseService } from 'src/app/service/course.service';
import { take } from 'rxjs/operators';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-course-table',
  templateUrl: './course-table.component.html',
  styleUrls: ['./course-table.component.css']
})
export class CourseTableComponent implements OnInit, AfterViewInit {
  totalPrice: number;
  registrations: Registration[];


  // registrations: Registration[] = [];

  displayedColumns = ['firstName', 'secondName', 'email', 'courseDate', 'startTime', 'sumParticipant', 'price', 'edit', 'delete'];

  dataSource = new MatTableDataSource<Registration>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  // registrations: Registration[];

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMesssages: FlashMessagesService,
    private courseService: CourseService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.registrationService.getRegistrations().subscribe((registrations) => {
      this.registrations = registrations;
      this.dataSource.data = registrations;

      this.getTotalPrice();
    });

  }

  getTotalPrice() {
    const total = this.registrations.reduce((total, registration) => {
      return total * registration.sumParticipant;
    }, 0);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDeleteRegistration(registration) {
    if (confirm('Wollen Sie diesen Teilnehmer wirklich unwiderruflich von der Liste löschen?')) {
      this.registrationService.deleteRegistration(registration.id);
      this.courseService
        .getCourseDetails(registration.courseId)
        .pipe(take(1))
        .subscribe((course) => {
          course.pTotal = course.pTotal - registration.sumParticipant;

          this.courseService.updateCourse(course);
          this.notificationService.warn('! Teilnehmer wurde erfolgreich gelöscht');
        });
      } else {
        this.notificationService.success(':: Teilnehmer wurde wurde nicht gelöscht');
      }
        

// Flash Message Action after deletion

      
      

    }
  }

