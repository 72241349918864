import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-courses',
  template: `
    <p>
      new-courses works!
    </p>
  `,
  styleUrls: ['./new-courses.component.css']
})
export class NewCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
