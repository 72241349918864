


import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { LoginComponent } from 'src/app/auth/login/login.component';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit, OnDestroy {

  @Output() closeSidenav = new EventEmitter<void>();
  isAuth = false;
  authSubscription: Subscription;

  constructor(
    private authServervice: AuthService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    this.authSubscription = this.authServervice.authChange.subscribe(authStatus => {
      this.isAuth = authStatus;
    });
  }

  onClose() {
    this.closeSidenav.emit();
  }

  onLogout(){
    this.onClose();
    this.authServervice.logout();
  }

  ngOnDestroy(){
    this.authSubscription.unsubscribe();
  }

  onLoginDialogSide() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45%';
    const dialogRef = this.dialog.open(LoginComponent, dialogConfig);
  }
}