import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Participant } from '../models/Participant';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  participantsCollection: AngularFirestoreCollection<Participant>;
    participantDoc: AngularFirestoreDocument<Participant>;
    participants: Observable<Participant[]>;
    participant: Observable<Participant>;

    constructor(private afs: AngularFirestore) {
        this.participantsCollection = this.afs.collection('participants',
            ref => ref.orderBy('choice', 'asc'));
    }
// Getting the Participants from the Database
    getParticipants(): Observable < Participant[] > {
        // Get the Participants with the id
        this.participants = this.participantsCollection.snapshotChanges().map(changes => {
            return changes.map(action => {
                const data = action.payload.doc.data() as Participant;
                data.id = action.payload.doc.id;
                return data;
            });

        });

        return this.participants;

    }
    // Taking the new Prticipants from the firebase
    newParticipant(participant: Participant) {
        this.participantsCollection.add(participant);
    }
    // Option participant selected
    optionParticipant(selectedId: string) {
        const selectedOption = this.getParticipants;

    }

    deleteParticipant(id) {
        this.participantDoc = this.afs.doc(`participants/${id}`);
        this.participantDoc.delete();
    }
}
