import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: [ './mat-confirm-dialog.component.css']
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(
    @Inject (MAT_DIALOG_DATA)public data,
    public dialogRef: MatDialogRef <MatConfirmDialogComponent> ) { }

  ngOnInit() {
  }

  onClose(){
    this.dialogRef.close(false);
  }

}

