import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Course } from '../models/Course';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  coursesCollection: AngularFirestoreCollection<Course>;
  courseDoc: AngularFirestoreDocument<Course>;
  courses: Observable<Course[]>;
  course: Observable<Course>;

  constructor(private afs: AngularFirestore) {
    this.coursesCollection = this.afs.collection('courses', (ref) => ref.orderBy('title', 'asc'));
  }

  getCourses(): Observable<Course[]> {
    // Get the Courses with the id
    this.courses = this.coursesCollection.snapshotChanges().map((changes) => {
      return changes.map((action) => {
        const data = action.payload.doc.data() as Course;
        data.id = action.payload.doc.id;

        this.formatDate(data);
        return data;
      });
    });
    return this.courses;
  }

  newCourse(course: Course) {
    course.startDate = (course.startDate as Date).getTime();
    this.coursesCollection.add(course);
  }

  // updateCourse(course: Course) {
  //   this.afs.doc('courses/' + course.id).update(course);
  // }

  updateCourse(course: Course) {
    course.startDate = (course.startDate as Date).getTime();
    this.courseDoc = this.afs.doc(`courses/${course.id}`);
    this.courseDoc.update(course);
  }

  getCourseDetails(id: string): Observable<Course> {
    this.courseDoc = this.afs.doc<Course>(`courses/${id}`);

    this.course = this.courseDoc.snapshotChanges().map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as Course;
        data.id = action.payload.id;
        this.formatDate(data);
        return data;
      }
    });

    return this.course;

  }
// Humman readerble DateFormat for show
  formatDate(course) {
    course.startDate = new Date(course.startDate);
    course.formattedDate = course.startDate.toLocaleDateString('de-DE');
  }

  deleteCourse(id) {
    this.courseDoc = this.afs.doc(`courses/${id}`);
    this.courseDoc.delete();
  }
}
