import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { RegistrationService } from 'src/app/service/registration.service';
import { Registration } from 'src/app/models/Registration';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ParticipantService } from 'src/app/service/participant.service';
import { Participant } from 'src/app/models/Participant';
import { Course } from 'src/app/models/Course';
import { CourseService } from 'src/app/service/course.service';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialog } from '@angular/material';


// import { Router } from '@angular/router';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.css']
})
export class CourseFormComponent implements OnInit {
  constructor(

    public dialog: MatDialog,

    private participantService: ParticipantService,
    private courseService: CourseService,
    private registrationService: RegistrationService,
    private flashMessage: FlashMessagesService,
    private notificationService: NotificationService,
    private router: Router
  ) { }
  course: Course;
  selected: string;

  participants: Participant[];

  registrations: Registration = {
    courseId: '',
    pTitle: '',
    firstName: '',
    secondName: '',
    startTime: '',
    email: '',
    courseDate: '',
    sumParticipant: 0,
    addressOwner: '',
    additionalNames: '',
    price: 0
  };

  @ViewChild('f.getRawValue()', { static: true }) form: any;

  @Output() courseRegister = new EventEmitter<void>();

  ngOnInit() {
    this.participantService.getParticipants().subscribe((participants) => {
      this.participants = participants;
      this.selected = this.participants[0].id;
    });

  }

  onSubmit({ value, valid }: { value: Registration; valid: boolean }) {
    if (!valid) {
      // Show Error
      this.flashMessage.show('Something is Wrong with the Form', {
        cssClass: 'alert-danger',
        timeout: 4000
      });

    } else {
      value.courseId = this.course.id;
      value.courseDate = this.course.formattedDate;
      value.startTime = this.course.startTime;
      value.price = this.getPrice(this.selected);
      value.sumParticipant = this.getParticipantsNumber(this.selected);
      this.course.pTotal = this.course.pTotal
        ? Number(this.course.pTotal) + Number(value.sumParticipant)
        : 0 + Number(value.sumParticipant);
      // Add new Contact
      this.registrationService.newRegister(value);

      //Closes
      this.dialog.closeAll();

      // Update
      this.courseService.updateCourse(this.course);

      // Show Success MSG with snackbar
      this.notificationService.success(':: Vielen Dank für Ihre verbindliche Anmeldung. Wir werden Ihnen zeitnah eine E-Mail mit den Anmelde- und Rechnungsdaten zukommen lassen. Informationen zum Datenschutz finden Sie unter der Rubrik „Datenschutz“ auf unserer Homepage.');

      // Redirect
      this.router.navigate(['']);
    }
  }

  getPrice(id) {
    return this.participants.find((participant) => {
      return participant.id === id;
    }).price;
  }

  getParticipantsNumber(id) {
    return Number(this.participants.find((participant) => {
      return participant.id === id;

    }).pTotal);
  }

  getAvailableSeats() {
    return this.course.capacity - this.course.pTotal;
  }


}
