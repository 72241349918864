import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { Course } from 'src/app/models/Course';
import { CourseService } from 'src/app/service/course.service';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig } from '@angular/material';
import { AddCourseComponent } from '../add-course/add-course.component';
import { EditCourseComponent } from '../edit-course/edit-course.component';
import { NotificationService } from 'src/app/service/notification.service';
import { DialogService } from 'src/app/service/dialog.service';



@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit, AfterViewInit {

  constructor(
    private courseService: CourseService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}
  courses: Course[] = [];

  displayedColumns = [
    'title',
    'capacity',
    'pTotal',
    'formattedDate',
    'startTime',
    'endTime',
    'details',
    'edit',
    'delete'
  ];

  dataSource = new MatTableDataSource<Course>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  searchKey: string;

  ngOnInit() {
    this.courseService.getCourses().subscribe((courses) => {
      this.courses = courses;
      this.dataSource.data = courses;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filetrValue: string) {
    this.dataSource.filter = filetrValue.trim().toLowerCase();
  }

  // onSearchClear(){
  //   this.searchKey= "";
  // }

  onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = '68%';
    this.dialog.open(AddCourseComponent, dialogConfig);
  }

  onEdit(course) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = '68%';
    this.dialog.open(EditCourseComponent, dialogConfig);
    const dialogRef = this.dialog.open(EditCourseComponent, dialogConfig);
    dialogRef.componentInstance.course = course;
  }


  onDeleteCourse(course) {
    // if (confirm('Wollen Sie diesen Kurs wirklich unwiderruflich von der Liste löschen?')) {
    //   this.courseService.deleteCourse(course.id);
    //   this.notificationService.warn('! Kurs erfolgreich gelöscht.');
    // }
    
    // else {
    //   this.notificationService.success(':: Kurs wurde nicht gelöscht!');
    // }
    this.dialogService.openConfirmDialog('Wollen Sie diesen Kurs wirklich unwiderruflich von der Liste löschen?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.courseService.deleteCourse(course.id);
        this.notificationService.warn('! Kurs erfolgreich gelöscht.');
      }

      else {
        this.notificationService.success(':: Kurs wurde nicht gelöscht!');
    }
    });
  }
   
}
