import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddUserComponent } from './auth/add-user/add-user.component';
import { LoginComponent } from './auth/login/login.component';
import { UsersListComponent } from './auth/users-list/users-list.component';
import { ContactComponent } from './contact-content/contact/contact.component';
import { ContactDetailsComponent } from './contact-content/contact-details/contact-details.component';
import { ContactFormComponent } from './contact-content/contact-form/contact-form.component';
import { CourseFormComponent } from './course/course-form/course-form.component';
import { CourseTableComponent } from './course/course-table/course-table.component';
import { CourseDetailsComponent } from './course/course-details/course-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { FooterComponent } from './footer/footer.component';
import { FrontContentComponent } from './front-content/front-content.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { NewCoursesComponent } from './new-courses/new-courses.component';
import { AddCourseComponent } from './new-courses/add-course/add-course.component';
import { CoursesComponent } from './new-courses/courses/courses.component';
import { NewcourseDetailsComponent } from './new-courses/newcourse-details/newcourse-details.component';
import { EditCourseComponent } from './new-courses/edit-course/edit-course.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivatePolicyComponent } from './private-policy/private-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { ContactService } from './service/contact.service';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MaterialModule } from './material.module';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AuthService } from './auth/auth.service';
import { CourseService } from './service/course.service';
import { RegistrationService } from './service/registration.service';
import { ParticipantService } from './service/participant.service';
import { DashboardLandComponent } from './dashboard-land/dashboard-land.component';



@NgModule({
  declarations: [
    AppComponent,
    AddUserComponent,
    LoginComponent,
    UsersListComponent,
    ContactComponent,
    ContactDetailsComponent,
    ContactFormComponent,
    CourseFormComponent,
    CourseTableComponent,
    CourseDetailsComponent,
    DashboardComponent,
    DashboardDetailsComponent,
    FooterComponent,
    FrontContentComponent,
    MatConfirmDialogComponent,
    NewCoursesComponent,
    AddCourseComponent,
    CoursesComponent,
    NewcourseDetailsComponent,
    EditCourseComponent,
    NotFoundComponent,
    PrivatePolicyComponent,
    TermsConditionsComponent,
    WelcomeComponent,
    HeaderComponent,
    SidenavListComponent,
    DashboardLandComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    MaterialModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase, 'eskino'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    FlashMessagesModule.forRoot()
  ],
  providers: [AuthService, CourseService, RegistrationService, ContactService, ParticipantService],
  bootstrap: [AppComponent],
  entryComponents: [ContactFormComponent, MatConfirmDialogComponent]
})
export class AppModule { }
