import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/models/Course';
import { CourseService } from 'src/app/service/course.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-newcourse-details',
  templateUrl: './newcourse-details.component.html',
  styleUrls: ['./newcourse-details.component.css']
})
export class NewcourseDetailsComponent implements OnInit {

  id: string;
  course: Course;

  constructor(
    private courseService: CourseService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessages: FlashMessagesService
  ) {}

  ngOnInit() {
    // Get id from url
    this.id = this.route.snapshot.params['id'];
    // Get individual Course
    this.courseService.getCourseDetails(this.id).subscribe(course => {
      this.course = course;
    });

    return this.course;
  }
}

