
import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/service/registration.service';

import { Registration } from 'src/app//models/Registration';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { FlashMessagesService } from 'angular2-flash-messages';
import { CourseService } from 'src/app/service/course.service';
import { Course } from 'src/app/models/Course';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent implements OnInit {
  id: string;
  registration: Registration;

  constructor(
    private registrationService: RegistrationService,
    private courseService: CourseService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMesssages: FlashMessagesService
    ) {}

    course: Course[];
  

  ngOnInit() {
    
    // Get from ID
    this.id = this.route.snapshot.params.id;
    // Get indidividual Registration
    this.registrationService.getRegistrationDetails(this.id).subscribe(registration => {
      this.registration = registration;
     
      
      return this.registration;
      
    });
      

      



  }

}




