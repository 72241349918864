import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';

import { FlashMessagesService } from 'angular2-flash-messages';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.authService.addUser({
      email: form.value.email,
      password: form.value.password,
    });
  }

}
