import { Component, OnInit } from '@angular/core';

import { CourseService } from '../service/course.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { Course } from '../models/Course';
import { CourseFormComponent } from '../course/course-form/course-form.component';
import { RegistrationService } from '../service/registration.service';
import { Registration } from '../models/Registration';



@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  courses: Course[];

  constructor(private courseService: CourseService, private dialog: MatDialog) {}

  // ngOnInit() {
  //   this.courseService.getCourses().subscribe((courses) => {
  //     this.courses = courses;
  //   });
  // }

  ngOnInit() {
    this.courseService.getCourses().subscribe((courses) => {
      this.courses = courses.sort(function(a, b) {
        return a.startDate - b.startDate;
      });
    });
  }

  // pass here total number of particpants

  onRegDialog(course) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = '1100px';
    const dialogRef = this.dialog.open(CourseFormComponent, dialogConfig);
    dialogRef.componentInstance.course = course;
    // dialogRef.componentInstance.participants = sumParticipant;
  }
  // this.notificationService.success(':: Course Added Successfully');

  
}