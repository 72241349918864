import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/service/contact.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Contact } from 'src/app/models/Contact';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.css']
})


export class ContactDetailsComponent implements OnInit {
  id: string;
  contact: Contact;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService

  ) {}

  ngOnInit() {

    // Get from ID
    this.id = this.route.snapshot.params.id;
    // Get individual Contact
    this.contactService.getContactDetails(this.id).subscribe(contact => {
      this.contact = contact;
    });

    return this.contact;
  }

}

