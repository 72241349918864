import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddCourseComponent } from './new-courses/add-course/add-course.component';
import { ContactComponent } from './contact-content/contact/contact.component';
import { CoursesComponent } from './new-courses/courses/courses.component';
import { CourseTableComponent } from './course/course-table/course-table.component';
import { CourseDetailsComponent } from './course/course-details/course-details.component';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';
import { ContactDetailsComponent } from './contact-content/contact-details/contact-details.component';
import { ContactFormComponent } from './contact-content/contact-form/contact-form.component';
import { NewcourseDetailsComponent } from './new-courses/newcourse-details/newcourse-details.component';
import { PrivatePolicyComponent } from './private-policy/private-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { EditCourseComponent } from './new-courses/edit-course/edit-course.component';
import { AuthGuard } from './auth/auth.guard';
import { CourseFormComponent} from './course/course-form/course-form.component';







const routes: Routes = [

  { path: '', component: WelcomeComponent },

  { path: 'registers', component: CourseTableComponent },
  { path: 'dashboard/details/registration/:id', component: CourseDetailsComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard] },
  { path: 'dashboard/details', component: DashboardDetailsComponent , canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'course/register', component: CourseFormComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'dashboard/details/contact/:id', component: ContactDetailsComponent, canActivate: [AuthGuard] },
  { path: 'course/add', component: AddCourseComponent },
  { path: 'dashboard/details/course/:id', component: NewcourseDetailsComponent,  canActivate: [AuthGuard] },
  { path: 'courses', component: CoursesComponent },
  { path: 'policy', component: PrivatePolicyComponent},
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'dashboard/details/course/edit/:id', component: EditCourseComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
