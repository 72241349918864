import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/service/course.service';
import { Course } from 'src/app/models/Course';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatDialog } from '@angular/material';
import { NotificationService } from 'src/app/service/notification.service';


@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.css']
})
export class EditCourseComponent implements OnInit {
  id: string;
  course: Course = {
    id: '',
    title: '',
    startDate: '',
    houseOwner: '',
    startTime: '',
    endTime: '',
    zipcode: '',
    streetAddress: '',
    pTotal: 0,
    capacity: 0
  };

  constructor(
    private courseService: CourseService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private flashMessages: FlashMessagesService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  onSubmit({ value }: { value: Course}) {
      this.courseService.updateCourse(value);
      this.dialog.closeAll();

      // Nofication
      this.notificationService.success(':: Der Kurs wurde erfolgreich aktualisiert.');
  }

}