



import { Component, OnInit, ViewChild } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DatePipe } from '@angular/common';


import { Router } from '@angular/router';
import { CourseService } from 'src/app/service/course.service';
import { NgForm } from '@angular/forms';
import { Course } from 'src/app/models/Course';
import { NotificationService } from 'src/app/service/notification.service';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {
  course: Course = {
    id: '',
    title: '',
    startDate: '',
    houseOwner: '',
    startTime: '',
    endTime: '',
    zipcode: '',
    streetAddress: '',
    pTotal: 0,
    capacity: 0
  };

  @ViewChild('ac.getRawValue()', {static: true}) form: any;

  constructor(
    // private flashMessage: FlashMessagesService,
    private courseService: CourseService,
    private router: Router,
    public dialog: MatDialog,
    private flashMessage: FlashMessagesService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {}

  onSubmit({ value, valid }: { value: Course; valid: boolean }) {
    if (!valid) {
      // Show Error
      this.flashMessage.show('Something is Wrong with the Form', {
        cssClass: 'alert-danger',
        timeout: 4000
      });
    } else {
      value.pTotal = 0;

      // Add new Course
      this.courseService.newCourse(value);

      // Closes
      this.dialog.closeAll();
      // Show Success MSG
      this.notificationService.success(':: Der neue Kurs wurde erfolgreich angelegt.');
    } 

  }
     
}

