import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NotificationService } from 'src/app/service/notification.service';
import { ContactFormComponent } from '../contact-content/contact-form/contact-form.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  onContactDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    const dialogRef = this.dialog.open(ContactFormComponent, dialogConfig);
    // dialogRef.componentInstance.course = course;
    // dialogRef.componentInstance.participants = sumParticipant;
  }

}

