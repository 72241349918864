import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Contact } from 'src/app/models/Contact';
import { ContactService } from 'src/app/service/contact.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from 'src/app/service/notification.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {
  contacts: Contact[];

  contactSubscription: Subscription;

  displayedColumns = ['firstName', 'lastName', 'email', 'phoneNumber', 'details', 'delete'];
  dataSource = new MatTableDataSource<Contact>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private contactService: ContactService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.contactService.getAvailableContacts().subscribe((contacts) => {
      this.contacts = contacts;
      this.dataSource.data = contacts;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filetrValue: string) {
    this.dataSource.filter = filetrValue.trim().toLowerCase();
  }

  onDeleteContact(contact) { 
    if (confirm('Wollen Sie diesen Kontakt wirklich unwiderruflich von der Liste löschen?')) {
      this.contactService.deleteContact(contact.id);
      this.notificationService.warn(':: Der Datensatz wurde erfolgreich gelöscht.');
    }

    // Flash Message Action after deletio
    else {
    this.notificationService.warn(':: Der Datensatz wurde nicht gelöscht.');
  }
}

}
